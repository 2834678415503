import { iconList } from '../../utils/iconList'

const LiveRoutesControls = ({ controlState, setControlState }) => {
    return (
      <section className="flex justify-end w-full space-x-4">
        <div className="flex items-center space-x-2">
          <span className="text-gray-700">Actividades</span>
          <label className="relative inline-flex items-center cursor-pointer">
            <input type="checkbox" value="" checked={controlState.resume} onChange={() => setControlState({ ...controlState, resume: !controlState.resume })} className="sr-only peer" />
            <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-orange-300 dark:peer-focus:ring-orange-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-600"></div>
          </label>
          <span className="text-gray-700">Resumen</span>
        </div>
        <div className="relative w-56">
          <input
            type="date"
            name=""
            id=""
            className="border font-light rounded px-10 py-1 w-full"
            value={controlState.date}
            onChange={(e) => setControlState({ ...controlState, date: e.target.value })}
            // Correcting the max date value by converting it into ISO string format
            max={new Date().toISOString().split('T')[0]}
          />
          <button
            onClick={() =>
              setControlState({
                ...controlState,
                date: new Date(
                  new Date(controlState.date).setDate(new Date(controlState.date).getDate() - 1)
                )
                  .toISOString()
                  .split('T')[0],
              })
            }
            className="absolute left-2 top-1/2 transform -translate-y-1/2 p-1 rounded-full hover:bg-gray-300 focus:outline-none"
          >
            <img src={iconList.arrowBackIos} alt="Previous day" className="w-4 h-4" />
          </button>
          <button
            onClick={() =>
              setControlState({
                ...controlState,
                date: new Date(
                  new Date(controlState.date).setDate(new Date(controlState.date).getDate() + 1)
                )
                  .toISOString()
                  .split('T')[0],
              })
            }
            className="absolute right-2 top-1/2 transform -translate-y-1/2 p-1 rounded-full hover:bg-gray-300 focus:outline-none"
          >
            <img src={iconList.arrowForward} alt="Next day" className="w-4 h-4" />
          </button>
        </div>
  
        <input onChange={(e) => setControlState({ ...controlState, searchQuery: e.target.value })} type="text" name="" id="" placeholder='Buscar repartidor' className='border rounded pl-3 py-1' />
  
      </section>
    )
  }

export default LiveRoutesControls