import React, { createContext, useEffect, useState } from "react";
import { getAccessTokenApi, getRefreshTokenApi, logout, refreshAccessToken } from "../api/token";
import { showToast } from "../utils/showToast";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { getUser } from "../api/user";

export const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const [authState, setAuthState] = useState({
    user: null,
    userData: null,
    isLoading: true,
  });
  const navigate = useNavigate();

  useEffect(() => {
    checkUserLogin(setAuthState);
  }, []);

  useEffect(() => {
    if (authState.user && authState.user.role === "delivery") {
      showToast("Sólo los usuarios de tipo cliente cuentan con acceso a este sistema", false);
      logout();
      navigate("/login");
    }
  }, [authState.user, navigate]);

  return <AuthContext.Provider value={authState}>{children}</AuthContext.Provider>;
}

async function checkUserLogin(setAuthState) {
  const accessToken = getAccessTokenApi();

  if (!accessToken) {
    const refreshToken = getRefreshTokenApi();

    if (!refreshToken) {
      logout();
      setAuthState({
        user: null,
        isLoading: false,
      });
    } else {
      refreshAccessToken(refreshToken).then(() => {
        // Después de refrescar el token, intentar obtener el usuario nuevamente
        checkUserLogin(setAuthState);
      });
    }
  } else {
    const user = jwtDecode(accessToken)
    if(user.id){
      const {data: {user: userData}} = await getUser({id: user.id})
      
      setAuthState({
        isLoading: false,
        user,
        userData
      });
    }
   
  }
}

/**
 * Returns an object containing information about the authentication status and role of the current user.
 *
 * @returns {Object} An object with the following properties:
 * - isAuthenticated: A boolean indicating whether the user is authenticated or not.
 * - role: The role of the authenticated user.
 */
export function useAuthToken() {
  const getToken = () => localStorage.getItem("accessToken");
  const getUserData = () => {
    const token = getToken();
    if (!token) return null;
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error("Failed to decode token", error);
      return null;
    }
  };

  const userData = getUserData();

  return {
    isAuthenticated: !!userData,
    role: userData?.role,
  };
}