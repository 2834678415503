import { BreadcrumbItem, Breadcrumb } from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import { iconList } from '../../utils/iconList'


const LiveRoutesBreadcrumb = () => {
    const navigate = useNavigate()
    return (
      <Breadcrumb className="custom-breadcrumb-container">
        <BreadcrumbItem
          className="d-flex align-items-center cursor-pointer"
          onClick={() => navigate("/admin")}
        >
          <img src={iconList.home} alt="home" className="mr-2" />
          <span>Inicio</span>
        </BreadcrumbItem>
        <BreadcrumbItem className="d-flex align-items-center cursor-pointer">
          <span>Flota</span>
        </BreadcrumbItem>
        <BreadcrumbItem className="d-flex align-items-center cursor-pointer">
          <span>En vivo</span>
        </BreadcrumbItem>
      </Breadcrumb>
  
    )
  }

  export default LiveRoutesBreadcrumb