import React, { useEffect, useState, useMemo } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { formatRut } from "../../utils/formatter";
import {
  packageStatus,
  packageStatusEvidenceStyles,
} from "../../utils/packageStatus";
import VerticalStepper from "../verticalStepper";
import Map from "../map";
import moment from "moment/moment";
import { getOnePackage } from "../../api/packages";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

// Funciones utilitarias, puedes moverlas a utils
const calcularHoras = (segundos) => {
  const horaActual = new Date();
  let hora1, hora2;
  if (segundos < 900) {
    hora1 = new Date(horaActual.getTime() + 30 * 60 * 1000);
    hora2 = new Date(horaActual.getTime() + 60 * 60 * 1000);
  } else if (segundos < 1800) {
    hora1 = new Date(horaActual.getTime() + 60 * 60 * 1000);
    hora2 = new Date(horaActual.getTime() + 90 * 60 * 1000);
  } else if (segundos < 3600) {
    hora1 = new Date(horaActual.getTime() + 90 * 60 * 1000);
    hora2 = new Date(horaActual.getTime() + 120 * 60 * 1000);
  } else {
    hora1 = new Date(horaActual.getTime() + 120 * 60 * 1000);
    hora2 = new Date(horaActual.getTime() + 240 * 60 * 1000);
  }
  
  return `${hora1.toLocaleTimeString('es-AR', { hour: '2-digit', minute: '2-digit' })} - ${hora2.toLocaleTimeString('es-AR', { hour: '2-digit', minute: '2-digit' })}`;
};

const downloadImagesAsZip = async (images, followingNumber) => {
  const zip = new JSZip();
  for (const [index, img] of images.entries()) {
    const response = await fetch(img.startsWith('data:image') ? img : await fetch(img));
    const blob = await response.blob();
    zip.file(`${followingNumber}-evidence-${index + 1}.jpg`, blob, { binary: true });
  }
  const content = await zip.generateAsync({ type: 'blob' });
  saveAs(content, `${followingNumber}-evidence.zip`);
};

const debugBase64 = (base64URL) => {
  const win = window.open("about:blank", "Evidencia");
  win.document.write(`<img src="${base64URL}" style="width:100%; height:100%; object-fit: contain;" />`);
};

export default function EvidenceView({ selectedPackage }) {
  const [evidencePackage, setEvidencePackage] = useState(null);

  const stepsValue = useMemo(() => ({
    step1: { value: evidencePackage?.status }
  }), [evidencePackage]);

  const deliveredHour = useMemo(() => {
    return evidencePackage?.[4]?.[evidencePackage?.[4].length - 1]?.updatedAt || null;
  }, [evidencePackage]);

  useEffect(() => {
    let isMounted = true;
    getOnePackage(selectedPackage?.correlative?.comparative).then((res) => {
      if (isMounted) setEvidencePackage(res);
    });
    return () => (isMounted = false); // Cleanup to prevent state update after unmount
  }, [selectedPackage]);

  if (!evidencePackage) return <div>Cargando...</div>;

  return (
    <div className="custom-evidence-container mt-4">
      <div className="mt-2">
        <span className="custom-evidence-container__title">
          Pedido #{evidencePackage?.followingNumber}
        </span>
      </div>
      <Row className="mt-4">
        {/* Resumen del pedido */}
        <Col xs="6" md="6" xl="6">
          <Card className="custom-evidence-card">
            <CardBody className="d-grid">
              <span className="custom-evidence-card__title">Resumen</span>
              <div className="custom-evidence-card__divider" />
              <span className="custom-evidence-card__subText">
                {evidencePackage?.status === 4
                  ? "Tu paquete fue entregado a las:"
                  : "El horario de entrega de tu paquete es:"}
              </span>
              <span className="custom-evidence-card__schedule mt-2">
                {evidencePackage?.status === 4
                  ? moment(deliveredHour).format("HH:mm")
                  : evidencePackage?.timeFromDelivery
                  ? calcularHoras(evidencePackage?.timeFromDelivery)
                  : "14:00 - 21:00"}
              </span>
              <span className="custom-evidence-card__date mt-2">
                {evidencePackage?.status === 4
                  ? moment(deliveredHour).format("DD MMM YYYY")
                  : moment(evidencePackage?.createdAt).format("DD MMM YYYY")}
              </span>
            </CardBody>
          </Card>

          {/* Detalles del pedido */}
          <Card className="custom-evidence-card mt-3">
            <CardBody className="d-grid">
              <span className="custom-evidence-card__title">Detalles del pedido</span>
              <div className="custom-evidence-card__divider" />
              {[
                { question: "Código de seguimiento:", response: evidencePackage?.followingNumber },
                { question: "Fecha de ingreso:", response: moment(evidencePackage?.createdAt).format("DD MMM YYYY - HH:mm") },
                { question: "Cliente:", response: `${evidencePackage?.receiver_name} ${evidencePackage?.receiver_lastName}` },
                { question: "Estado:", response: packageStatus[evidencePackage?.status], style: packageStatusEvidenceStyles[evidencePackage?.status] }
              ].map((detail, index) => (
                <div className="d-flex justify-content-between mt-3" key={index}>
                  <span className="custom-evidence-card__question">{detail.question}</span>
                  <span className={`custom-evidence-card__response ${detail.style || ''}`}>{detail.response}</span>
                </div>
              ))}
            </CardBody>
          </Card>

          {/* Eventos */}
          <Card className="custom-evidence-card mt-3">
            <CardBody className="d-grid">
              <span className="custom-evidence-card__title">Eventos</span>
              <div className="custom-evidence-card__divider" />
              <VerticalStepper stepStatus={stepsValue} data={evidencePackage} />
            </CardBody>
          </Card>
        </Col>

        {/* Dirección y evidencia */}
        <Col xs="6" md="6" xl="6">
          <Card className="custom-evidence-card mb-3">
            <CardBody className="d-grid">
              <span className="custom-evidence-card__title">
                {evidencePackage?.status === 4
                  ? "Dirección de entrega"
                  : evidencePackage?.status === 6
                  ? "Dirección de intento de entrega"
                  : "Dirección de entrega programada"}
              </span>
              <div className="custom-evidence-card__divider" />
              <span className="custom-evidence-card__response">{selectedPackage?.destiny}</span>
              {(evidencePackage?.status === 4 || evidencePackage?.status === 6) && (
                <Map
                  coords={evidencePackage?.status === 6 ? evidencePackage?.failed?.coords : evidencePackage?.coords}
                  id="map"
                />
              )}
            </CardBody>
          </Card>

          {/* Detalles de la entrega */}
          <Card className="custom-evidence-card">
            <CardBody className="d-grid">
              <span className="custom-evidence-card__title">Detalles de la entrega</span>
              <div className="custom-evidence-card__divider" />
              {[
                { question: "Nombre de quién recibe:", response: `${evidencePackage?.realReceiver?.name} ${evidencePackage?.realReceiver?.lastName}` },
                { question: "RUT de quién recibe:", response: formatRut(evidencePackage?.realReceiver?.rut) },
                { question: "Hora de entrega:", response: evidencePackage[4]?.length > 0 ? moment(deliveredHour).format("DD MMM YYYY - HH:mm") : "" }
              ].map((detail, index) => (
                <div className="d-flex justify-content-between mt-3" key={index}>
                  <span className="custom-evidence-card__question">{detail.question}</span>
                  <span className="custom-evidence-card__response">{detail.response}</span>
                </div>
              ))}
            </CardBody>
          </Card>

          {/* Evidencia */}
          <Card className="custom-evidence-card mt-3">
            <CardBody className="d-grid">
              <div className="d-flex justify-content-between align-items-center">
                <span className="custom-evidence-card__title">
                  {evidencePackage?.status === 4 ? "Evidencia de entrega" : "Evidencia de intento de entrega"}
                </span>
                {(evidencePackage?.images?.length > 0) && (
                  <button onClick={() => downloadImagesAsZip(evidencePackage.images, evidencePackage.followingNumber)} className="download-btn">
                    Descargar todas las imágenes
                  </button>
                )}
              </div>
              <div className="custom-evidence-card__divider" />
              <div className="w-100 d-flex items-center gap-2 flex-col justify-content-center">
                {evidencePackage?.images?.length > 0 && evidencePackage.images.map((img, index) => (
                  <img key={index} className="custom-proof-img" src={img} alt="evidence" onClick={() => debugBase64(img)} />
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
