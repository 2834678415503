import { useState, useEffect } from 'react';
import useDeliveredStore from '../../store/delivered';
import Spinner from '../../components/spinner';
import { CalendarOffIcon } from 'lucide-react';
import formatDate from '../../utils/formatDate';
import { MapPin, Building, PackageCheck, PackageX, User, Calendar, X, CheckIcon, CreditCard } from 'lucide-react'; // Icons for data

const getIndexHour = (index) => ((index + 8) % 24).toString().padStart(2, '0') === '00' ? '00' : ((index + 8) % 24).toString().padStart(2, '0');

function getEarliestDate(data) {
    return data.reduce((earliest, current) => {
        const currentDate = new Date(current.lastStatusChange);
        const hours = currentDate.getUTCHours();

        // Only consider dates from 08:00 to 00:00
        if (hours >= 8 && hours < 24) {
            // If earliest is null or currentDate is earlier, update earliest
            if (earliest === null || currentDate < earliest) {
                return currentDate;
            }
        }
        return earliest;
    }, null);
}

const PackageStatus = ({ pkg }) => {
    const [isTooltipVisible, setTooltipVisible] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const packageMinutes = new Date(pkg.lastStatusChange).getUTCMinutes();

    const handleMouseEnter = () => {
        setTooltipVisible(true);
    };

    const handleMouseLeave = () => {
        setTooltipVisible(false);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div
                key={pkg.id}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={() => setIsModalOpen(true)}
                className={`bottom-[10px] w-5 h-5 p-[2px] hover:z-50 hover:border border-white flex items-center cursor-pointer mx-auto absolute rounded-full 
                ${pkg.status === 4 ? 'bg-green-500' : 'bg-red-500'} flex items-center justify-center`}
                style={{ transform: `translateX(${packageMinutes / 2.3}px)` }}
            >
                {pkg.status === 4 ? <CheckIcon className="text-white" /> : <X className="text-white" />}

                {isTooltipVisible && (
                    <span className="absolute bottom-[30px] z-50 left-1/2 transform whitespace-nowrap -translate-x-1/2 p-1 text-xs text-white bg-gray-800 rounded">
                        {pkg.address_line} <br /> {pkg.city_name}
                    </span>
                )}
            </div>

            {isModalOpen && (
                <div
                    className="fixed inset-0 flex items-center justify-center z-50 bg-gray-800 bg-opacity-75"
                    onClick={closeModal}
                >
                    <div
                        className="bg-white rounded-lg p-4 w-96 relative"
                        onClick={(e) => e.stopPropagation()} // Prevents closing the modal when clicking inside it
                    >
                        <button
                            className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
                            onClick={closeModal}
                        >
                            <X />
                        </button>

                        <h2 className="text-xl font-bold mb-4">Detalles del Paquete</h2>

                        {/* Address */}
                        <div className="flex items-center mb-2">
                            <MapPin className="mr-2 text-gray-600" />
                            <p>{pkg.address_line}</p>
                        </div>

                        {/* City */}
                        <div className="flex items-center mb-2">
                            <Building className="mr-2 text-gray-600" />
                            <p>{pkg.city_name}</p>
                        </div>

                        {/* Status */}
                        <div className="flex items-center mb-2">
                            {pkg.status === 4 ? (
                                <PackageCheck className="mr-2 text-green-500" />
                            ) : (
                                <PackageX className="mr-2 text-red-500" />
                            )}
                            <p>{pkg.status === 4 ? 'Entregado' : 'No Entregado'}</p>
                        </div>

                        {/* Receiver Name */}
                        <div className="flex items-center mb-2">
                            <User className="mr-2 text-gray-600" />
                            <p>{pkg.realReceiver_name}</p>
                        </div>

                        {/* Receiver RUT */}
                        <div className="flex items-center mb-2">
                            <CreditCard className="mr-2 text-gray-600" />
                            <p>{pkg.realReceiver_rut}</p>
                        </div>

                        {/* Last Status Change */}
                        <div className="flex items-center mb-2">
                            <Calendar className="mr-2 text-gray-600" />
                            <p>{new Date(pkg.lastStatusChange).toLocaleString()}</p>
                        </div>

                        {/* Package Image */}
                        {pkg.images && pkg.images.length > 0 && (
                            pkg.images.length > 1 ? (
                                <div className="mt-4 overflow-x-scroll flex space-x-2">
                                    {pkg.images.map((image, index) => (
                                        <img
                                            key={index}
                                            src={image}
                                            alt={`Imagen ${index + 1} del Paquete`}
                                            className=" object-contain rounded"
                                        />
                                    ))}
                                </div>
                            ) : (
                                <div className="mt-4">
                                    <img
                                        src={pkg.images[0]}
                                        alt="Imagen del Paquete"
                                        className="w-full h-auto rounded"
                                    />
                                </div>
                            )
                        )}

                    </div>
                </div>
            )}
        </>
    );
};

const renderTableData = (resume, delivered) => {
    if (resume) {
        return [
            //CABECERA DE LA TABLA RESUMEN
            <>
                {/* MAPEA LAS 24 HORAS DEL DIA Y WEA */}
                {[...Array(17)].map((_, index) => {
                    const hour = (index + 8) % 24;
                    const displayHour = hour === 0 ? '00' : hour.toString().padStart(2, '0');
                    return (
                        <th key={index} className={`bg-gray-200 min-w-14 font-semibold text-center px-2 py-1 text-sm ${index === 16 && 'rounded-tr-lg'}`}>
                            {displayHour}
                        </th>
                    );
                })}
            </>,
            //CUERPO DE LA TABLA RESUMEN
            <>
                {
                    delivered.map(({ delivererFullName, packages }) => {
                        return (
                            <tr>
                                <td className="border px-4 py-2 w-1/5">{delivererFullName.trim() === "" ? "Sin nombre" : delivererFullName}</td>
                                {[...Array(17)].map((_, index) => {
                                    const indexHour = getIndexHour(index);

                                    // Find the package with the matching hour
                                    const matchedPackages = packages.filter(pkg => {
                                        const packageHour = new Date(pkg.lastStatusChange).getUTCHours() - 3;
                                        return packageHour.toString().padStart(2, '0') === indexHour;
                                    });

                                    return (
                                        <td key={index} className="border-y-2 relative h-5 border-x-2 border-dotted px-2 py-1 text-center">
                                            {matchedPackages && matchedPackages.map(pkg => <PackageStatus pkg={pkg} />)}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })
                }

            </>
        ]
    } else {
        return [
            <>

                <th className="bg-gray-200 px-4 py-2">Comuna</th>
                <th className="bg-gray-200 px-4 py-2">Asignados</th>
                <th className="bg-gray-200 px-4 py-2">Entregados</th>
                <th className="bg-gray-200 px-4 py-2">Fallidos</th>
                <th className="bg-gray-200 px-4 py-2 rounded-tr-lg">Cumplimiento</th>

            </>,
            <>
                {
                    delivered.map(({ delivererFullName, packages }) => (
                        <tr className='&>td:text-center border-x border-b'>
                            <td className="px-4 py-2 flex flex-col"><span className='text-gray-700 font-semibold'>{delivererFullName}</span> <span className='text-md text-gray-500'>example@gmail.com</span></td>
                            <td className=" px-4 py-2">Santiago</td>
                            <td className=" px-4 py-2">10</td>
                            <td className=" px-4 py-2">20</td>
                            <td className=" px-4 py-2">0</td>
                            <td className="px-4 py-2 flex items-center">
                                <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-orange-100">
                                    <div className="bg-orange-500 h-2.5 rounded-full" style={{ width: `50%` }}></div>
                                </div>
                                <span className="text-xs ml-2">
                                    {/* {delivery.assigned > 0 ? ((delivery.delivered / delivery.assigned) * 100).toFixed(2) : 0}% */}
                                </span>
                            </td>
                        </tr>
                    ))
                }
            </>
        ]
    }
}

const LiveRoutesTable = ({ controlState }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { delivered, fetchDelivered } = useDeliveredStore(store => store)
    const formattedDate = formatDate(controlState.date)


    const [tableHead, tableBody] = renderTableData(controlState.resume, (delivered[formattedDate]?.filter(({ delivererFullName }) => controlState.searchQuery ? delivererFullName.toLowerCase().includes(controlState.searchQuery) : true) || []).sort((d, _d) => getEarliestDate(d.packages) - getEarliestDate(_d.packages)));


    useEffect(() => {
        if(!delivered[formattedDate]){
            setIsLoading(true);
            fetchDelivered(controlState.date).then(() => setIsLoading(false));
        }
    }, [controlState.date]);

    if (isLoading) return <div className='w-full h-[60vh] border-2 rounded-xl mt-8 flex items-center flex-col gap-2 justify-center'><Spinner /><span className='text-gray-700 text-xl font-semibold'>Cargando entregas...</span></div>;
    if (!delivered[formattedDate]?.length) return <div className='w-full h-[60vh] border-2 rounded-xl mt-8 flex items-center flex-col gap-2 justify-center'><CalendarOffIcon className='w-10 h-10'></CalendarOffIcon><span className='text-gray-700 text-xl font-semibold'>No se encontraron paquetes</span></div>;
    return (
        <div className="mt-8">
            <table className="w-full overflow-y-scroll overflow-x-auto border-collapse">
                <thead className='sticky top-0 z-30'>
                    <tr className=' border-gray-200'>
                        <th className=" rounded-tl-lg bg-gray-200 px-4 py-2 whitespace-nowrap">Repartidor</th>
                        {tableHead}
                    </tr>
                </thead>
                <tbody>
                    {tableBody}
                </tbody>
            </table>


        </div>
    )
}

export default LiveRoutesTable