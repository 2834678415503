import Axios from "../config/axios-config";
import AxiosV2 from "../config/axios-v2-config";

export const getUser = async ({ id }) => {
  const userData = await Axios.get(`user/${id}`).catch((err) => {
    return { error: err.response.data };
  });

  return userData;
};

export const getDeliveries = async () => {
  const deliveries = await Axios.get("users-deliverys-paginated").catch(
    (err) => {
      return { error: err.response.data };
    }
  );

  return deliveries;
};

export const getDeliveriesListed = async () => {
  const deliveries = await Axios.get("users-deliverys/listed").catch((err) => {
    return { error: err.response.data };
  });

  return deliveries;
};

export const getAllUsers = async () => {
  const users = await Axios.get("users").catch((err) => {
    return { error: err.response.data };
  });

  return users;
};

export const getDeliveryUsers = async () => {
  return await Axios.get("users-deliverys").catch((err) => {
    return { error: err.response.data };
  });
};

export const flexToken = async (values) => {
  const response = await Axios.post("https://integrations-qa.beartrack.cl/mercadolibre/flex-token", values).catch((err) => {
    return { error: err.response.data };
  });

  return response;
};

export const deleteIntegrationApi = async (values) => {
  const response = await Axios.put("integration", values).catch((err) => {
    return { error: err.response.data };
  });

  return response;
};

export const changeAliasApi = async (values) => {
  const response = await Axios.patch("integration", values).catch((err) => {
    return { error: err.response.data };
  });

  return response;
};

export const addWoocommerce = async (values) => {
  return await AxiosV2.post(
      "users/integrations/woocommerce",
      values
  ).catch((err) => {
    return {error: err.response.data};
  });
};

export const addFalabella = async (values) => {
  return await AxiosV2.post("users/integrations/falabella", values).catch(
      (err) => {
        return {error: err.response.data};
      }
  );
};

export const addSendu = async (values) => {
  return await AxiosV2.post("users/integrations/sendu", values).catch(
      (err) => {
        return {error: err.response.data};
      }
  );
};

export const getActiveClientsApi = async () => {
  const clients = await Axios.get("active-clients").catch((err) => {
    return { error: err.response.data };
  });

  return clients;
};

//ksandoval 09-07-2024
export const getActiveClientsApiNew = async () => {
  const clients = await Axios.get("active-clients")
    .then(response => {
      const data = response.data.map(client => ({
        socialRazon: client.socialRazon ?? null,
        rut: client.rut ?? null,
        fantasyName: client.fantasyName ?? null,
        fiscalAddress: client.fiscalAddress ?? null,
        name: client.name ?? null,
        email: client.email ?? null,
        phone: client.phone ?? null,
        senderAddress: client.senderAddress ?? null,
        preferedHour: client.preferedHour ?? null,
        namePayPerson: client.namePayPerson ?? null,
        emailPayPerson: client.emailPayPerson ?? null,
        role: client.role ?? null
      }));
      return data;
    })
    .catch(err => {
      return { error: err.response.data };
    });

  return clients;
};
//ksandoval 09-07-2024

export const getNewUsers = async (page = 1, limit = 10) => {
  return await Axios.get(`new-users?page=${page}&limit=${limit}`).catch(
    (err) => {
      return { error: err.response.data };
    }
  );
};

//este endpoint se usa para bloquear o desbloquear usuarios  - Dvlprchris
export const activateUserApi = async ({ userId, status }) => {
  const activatedUser = await Axios.put(`activate-user/${userId}`, {
    active: status,
  }).catch((err) => {
    return { error: err.response.data };
  });

  return activatedUser;
};

export const getInActiveUsers = async (page = 1, limit = 10, searchTerm = "") => {
  let url = `inactiveUsers?page=${page}&limit=${limit}`;

  if (searchTerm !== "" && searchTerm !== null && searchTerm !== "null") {
    url = `inactiveUsers?page=${page}&limit=${limit}&searchTerm=${searchTerm}`;
  }

  return await Axios.get(url).catch((err) => {
    return { error: err.response.data };
  });
};


export const getActiveUsers = async (page = 1, limit = 10, searchTerm = "") => {
  let url = `active-users?page=${page}&limit=${limit}`;

  if (searchTerm !== "" && searchTerm !== null && searchTerm !== "null") {
    url = `active-users?page=${page}&limit=${limit}&searchTerm=${searchTerm}`;
  }

  return await Axios.get(url).catch((err) => {
    return { error: err.response.data };
  });
};

export const deleteUserApi = async ({ userId }) => {
  const deletedUser = await Axios.delete(`delete-user/${userId}`).catch(
    (err) => {
      return { error: err.response.data };
    }
  );

  return deletedUser;
};

export const updateUserApi = async ({ userId, values }) => {
  console.log("llego a la API update-user");
  return; //quitar return para llegar al backend
  const userUpdated = await Axios.put(`update-user/${userId}`, {
    ...values,
  }).catch((err) => {
    return { error: err.response.data };
  });

  return userUpdated;
};


// API V2 - 04-07
// nuevos metodos segun curlV2 - form data
// Crear un usuario de delivery
export const createDeliveryUser = async (data) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("email", data.email);
  formData.append("phone", data.phone);
  formData.append("driveLicense", data.driveLicense);
  formData.append("carDocument", data.carDocument);
  formData.append("carId", data.carId);
  formData.append("namePayPerson", data.namePayPerson);
  formData.append("rutPayPerson", data.rutPayPerson);
  formData.append("accountType", data.accountType);
  formData.append("bank", data.bank);
  formData.append("role", "delivery");

  console.log("FormData entries delivery:");
  formData.forEach((value, key) => {
    console.log(key, value);
  });

  try {
    const response = await AxiosV2.post("/users", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "authorization": `Bearer ${data.token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

// Actualizar un usuario de delivery
export const updateDeliveryUser = async (id, data) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("email", data.email);
  formData.append("phone", data.phone);
  formData.append("driveLicense", data.driveLicense);
  formData.append("carDocument", data.carDocument);
  formData.append("carId", data.carId);
  formData.append("namePayPerson", data.namePayPerson);
  formData.append("rutPayPerson", data.rutPayPerson);
  formData.append("accountType", data.accountType);
  formData.append("bank", data.bank);
  formData.append("role", "delivery");
  console.log("contenido: ", formData);

  try {
    const response = await AxiosV2.put(`/users/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: `Bearer ${data.token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

// Crear un usuario de ventas
export const createClientUser = async (data) => {
  console.log("in createClientUser data:::", data);
  console.log("in token data:::", data.token);
  const formData = new FormData();
  formData.append("socialRazon", data.socialRazon);
  formData.append("rut", data.rut);
  formData.append("fantasyName", data.fantasyName);
  formData.append("fiscalAddress", data.fiscalAddress);
  formData.append("name", data.name);
  formData.append("email", data.email);
  formData.append("phone", data.phone);
  formData.append("senderAddress", data.senderAddress);
  formData.append("preferedHour", data.preferedHour);
  formData.append("namePayPerson", data.namePayPerson);
  formData.append("emailPayPerson", data.emailPayPerson);
  formData.append("role", "client");

  console.log("FormData entries:");
  formData.forEach((value, key) => {
    console.log(key, value);
  });

  try {
    const response = await AxiosV2.post("/users", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "authorization": `Bearer ${data.token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};

// Actualizar un usuario de ventas
export const updateClientUser = async (id, data) => {
  const formData = new FormData();
  formData.append("socialRazon", data.socialRazon);
  formData.append("rut", data.rut);
  formData.append("fantasyName", data.fantasyName);
  formData.append("fiscalAddress", data.fiscalAddress);
  formData.append("name", data.name);
  formData.append("email", data.email);
  formData.append("phone", data.phone);
  formData.append("senderAddress", data.senderAddress);
  formData.append("preferedHour", data.preferedHour);
  formData.append("namePayPerson", data.namePayPerson);
  formData.append("emailPayPerson", data.emailPayPerson);
  formData.append("role", "client");

  try {
    const response = await AxiosV2.post(`/users/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        authorization: `Bearer ${data.token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(
      error.response ? error.response.data.message : error.message
    );
  }
};
