import React, { useState, useEffect } from 'react'




import LiveRoutesBreadcrumb from './LiveRoutesBreadcrumb';
import LiveRoutesControls from './LiveRoutesControls';
import LiveRoutesTable from './LiveRoutesTable';

export default function LiveRoutes() {
  const [controlState, setControlState] = useState({
    date: new Date().toISOString().split('T')[0],
    resume: true,
    searchQuery: ''
  });
  return (
    <div className=' w-[95%] h-screen mx-auto'>
      <span><LiveRoutesBreadcrumb /></span>
      <h1 className='text-left text-2xl font-bold'>Rutas en vivo</h1>
      <LiveRoutesControls controlState={controlState} setControlState={setControlState} />

      {controlState && <LiveRoutesTable controlState={controlState} />}





    </div>
  )
}
